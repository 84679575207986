import * as React from 'react'
import Layout from '../../../templates/layout'
import SloganPage from '../../../components/molecules/SloganPage'
import Seo from '../../../helpers/seo'
import HeaderContentSolution from '../../../components/organisms/HeaderContentSolution'
import ContentSolution from '../../../components/organisms/ContentSolution'
import TabsInformation from '../../../components/molecules/TabsInformation'
import marketingJson from './marketing.json'
import ContactForm from '../../../components/organisms/ContactForm'
import CasesRelational from '../../../components/organisms/CasesRelational'
import { assignIDToSolutionsJson } from '../../../helpers/assignIDToSolutionsJson'

const SolutionsMarketing = () => {
  const { branding, socialMedia, trafic, mediaMonitoring, relational } =
    assignIDToSolutionsJson(marketingJson)

  const tabContent = [
    {
      id: 1,
      heading: 'Branding & Design',
      content: () => {
        return branding.map((data) => {
          return (
            <ContentSolution
              icon={data.icon}
              title={data.title}
              description={data.description}
              key={data.id}
            />
          )
        })
      },
      label: 'branding',
      selected: 'close',
    },
    {
      id: 2,
      heading: 'Mídias digitais',
      content: () => {
        return socialMedia.map((data) => {
          return (
            <ContentSolution
              icon={data.icon}
              title={data.title}
              description={data.description}
              key={data.id}
            />
          )
        })
      },
      label: 'midias',
      selected: 'close',
    },
    {
      id: 3,
      heading: 'Tráfego',
      content: () => {
        return trafic.map((data) => {
          return (
            <ContentSolution
              icon={data.icon}
              title={data.title}
              description={data.description}
              key={data.id}
            />
          )
        })
      },
      label: 'trafic',
      selected: 'close',
    },
    {
      id: 4,
      heading: 'Media Monitoring',
      content: () => {
        return mediaMonitoring.map((data) => {
          return (
            <ContentSolution
              icon={data.icon}
              title={data.title}
              description={data.description}
              key={data.id}
            />
          )
        })
      },
      label: 'monitoring',
      selected: 'close',
    },
  ]

  return (
    <Layout page="SolutionsMarketing">
      <SloganPage title={'Marketing digital'} type={'marketing'} />
      <HeaderContentSolution
        icon={'marketing'}
        description={`<p>Se você precisa aumentar as vendas, divulgar um produto/serviço, melhorar o relacionamento com os clientes ou tornar a sua marca mais conhecida, <strong>trabalhar o posicionamento digital é uma etapa fundamental.</strong> Contamos com soluções para todos os tipos de estratégias dentro do Marketing Digital a fim de alavancar o seu negócio.</p>`}
      />
      <TabsInformation tabColor="orange-dark" tabContent={tabContent} />
      <ContactForm />
      <CasesRelational relationalData={relational} />
    </Layout>
  )
}

export const Head = () => <Seo title="Soluções" />

export default SolutionsMarketing
